<template>
  <ReflectSelectDateRange
    :date-range="selectedPeriod.dateRange"
    :can-select-custom-date-range="true"
    :selected-choice="selectedPeriodFormatted"
    :choices="periods"
    :is-locked="isLocked"
    @set-date-range="setSelectedPeriodFromDateRange"
    @set-choice="setSelectedPeriodFromChoice"
  />
</template>

<script lang="ts">
import { mapActions, mapState } from 'pinia'
import { formatPeriodOptions, formatSelectedPeriod } from '~/services/period'
import { useTimeDimensionStore } from '~/stores/timeDimension'

export default {
  name: 'WrapperSelectDateRange',
  props: {
    isLocked: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(useTimeDimensionStore, ['selectedPeriod', 'options']),
    isCustomPeriodSelected() {
      return this.selectedPeriod.periodId === 'CUSTOM'
    },
    selectedPeriodFormatted() {
      return formatSelectedPeriod(
        this.isCustomPeriodSelected,
        this.selectedPeriod
      )
    },
    periods() {
      return formatPeriodOptions(this.options)
    }
  },
  methods: {
    ...mapActions(useTimeDimensionStore, ['setSelectedPeriodFromPeriodId']),
    async setSelectedPeriodFromChoice(choice: { label: string; id?: string }) {
      const period = this.options.find(option => option.periodId === choice.id!)
      await this.setSelectedPeriodFromPeriodId({
        periodId: period?.periodId
      })
    },
    async setSelectedPeriodFromDateRange(dateRange: string[]) {
      const { setSelectedPeriodFromDateRange } = useTimeDimensionStore()
      await setSelectedPeriodFromDateRange({
        dateRange
      })
    }
  }
}
</script>
